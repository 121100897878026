.transfer-pension-insurance-signing-list {
  .list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .list-item {
    --blue: var(--lysa-brand-blue);
    --radius: 1rem;
    --rotate: 0deg;
    position: relative;
    padding: 1.8rem 2.2rem;
    background-color: var(--color-gray-50);
    border-radius: 1rem;
    overflow: hidden;

    .content {
      position: relative;
    }

    .glow {
      position: absolute;
      inset: 0;
      &:before {
        content: "";
        background: conic-gradient(
          from var(--rotate),
          transparent 0%,
          var(--blue) 10%,
          transparent 20%
        );
        position: absolute;
        inset: 0;
        animation: spin 3s linear infinite;
      }
      &:after {
        content: "";
        background-color: var(--color-gray-50);
        position: absolute;
        inset: 2px;
        border-radius: calc(var(--radius) - 2px);
      }
    }
  }

  .list-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    .list-item-header-left {
      display: flex;
      align-items: center;
      gap: 1.6rem;

      .list-item-header-left__pension-logo {
        flex-shrink: 0;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .container {
    margin: 1rem 0;
    display: grid;
    place-items: center;
    gap: 0.8rem;
  }

  .actions {
    margin-top: 1rem;
  }

  .qr-container {
    margin-top: 1rem;
    display: grid;
    place-items: center;

    .qr-wrapper {
      width: auto;
      background-color: var(--lysa-background-card);
      padding: 0.2rem;
      display: grid;
      place-items: center;
    }
  }

  hr {
    height: 1px;
    background-color: var(--lysa-border-black);
    opacity: 0.1;
    border: none;
    margin: 1.4rem 2rem 0 2rem;
  }

  .qr-instructions {
    margin-top: 2rem;
    list-style: decimal;
    font-size: small;

    & > li {
      margin-bottom: 10px;
    }

    & > li:last-child {
      margin-bottom: 0;
    }
  }

  .error-container {
    margin-bottom: 2rem;
  }

  .next {
    margin-top: 6rem;
  }
}

@property --rotate {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes spin {
  to {
    --rotate: 360deg;
  }
}
