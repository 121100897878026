.transfer-pension-move-time-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-radius: var(--lysa-card-border-radius);
  background: var(--lysa-background-card-gray-box);
  text-align: left;
}
