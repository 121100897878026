.transfer-pension-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .list-item {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin: 2rem 0;
    text-align: left;

    .list-item-text {
      display: flex;
      flex-direction: column;
    }

    .icon-circle {
      align-self: flex-start;
    }

    .list-item-body {
      padding: 0;
      margin: 0;
    }

    .read-more-button {
      background: none;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid var(--lysa-text-secondary);
      margin: 0;
      padding: 0;
    }
  }

  .icon-circle {
    display: grid;
    place-items: center;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 100%;
  }

  .time-estimate {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    color: var(--lysa-text-secondary);
  }

  .divider {
    width: 100%;
    border-top: 1px solid var(--color-gray-200);
    margin: 2rem 0 1rem 0;
  }

  .calculation-information {
    text-align: left;
    align-self: flex-start;
  }

  .read-more {
    margin: 1rem 0 2rem 0;
  }

  .next {
    margin-top: 3rem;
  }
}

.transfer-pension-intro-read-more-footer {
  color: var(--lysa-text-secondary);
}
