.transfer-pension-repayment {
  display: flex;
  flex-direction: column;
  align-items: center;

  .read-more {
    margin: 0;
    padding: 0.2rem 0 !important;
    min-height: auto;
    line-height: normal;

    .typography--label {
      line-height: 16px;
    }
  }

  .lysa-ui-radio-card-group {
    width: 100%;
  }

  .label-row {
    padding: 1.4rem;
  }

  .download-division {
    padding: 1rem;
  }
}
