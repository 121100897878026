.transfer-pension-story-payout-plan {
  .selections-card {
    margin-top: 32px;
  }

  .list {
    list-style: initial;
    margin-left: 2rem;
    margin-bottom: 2rem;
    padding: 1rem 0;
  }
}
