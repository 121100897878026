.transfer-pension-story-advice {
  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .heading {
      margin: 0;
    }
  }

  .show-investment-basis {
    margin: 0;
    padding: 0.2rem 0 !important;
    min-height: auto;
    line-height: normal;

    .typography--label {
      line-height: 16px;
    }
  }

  .type-card {
    dl {
      display: flex;
      justify-content: space-between;
    }

    dt {
      font-weight: bold;
    }

    dd {
      display: flex;
      align-items: center;

      .type-text {
        margin-right: 8px;
      }
    }
  }

  .disclaimer {
    margin-top: 32px;
  }

  .payout-section {
    margin: 2rem 0;
  }

  .payout-card {
    box-shadow: none;
    border: var(--lysa-card-border-size) solid var(--lysa-card-border);
    margin-bottom: 0;
  }

  .action-buttons {
    margin-top: 2rem;
  }

  .summary {
    margin: 2rem 0;
    box-shadow: none;
    background-color: var(--color-gray-50);
    padding: 8px 0;

    .card-list-item {
      border: none;
      padding: 8px 16px;
    }

    dl {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0;
      gap: 16px;

      dt,
      dd {
        margin: 0;
        width: auto;
        display: flex;
        gap: 0.6rem;
        align-items: center;
      }
    }

    .repayment-information-button {
      border: none;
      min-height: 0;
      height: 18px;
      width: 18px;
    }
  }

  .investment-type {
    margin: 3rem 0 3rem 0;

    dl {
      margin-top: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 16px;

      dt,
      dd {
        margin: 0;
        width: auto;
        display: flex;
        gap: 0.6rem;
        align-items: center;
      }
    }
  }

  .divider {
    border-color: hsla(0, 100%, 0%, 0.15);
    margin: 2rem 0;
  }

  .selectable-card {
    /* Override default SelectableCard style  */
    box-shadow: none !important;
    margin-bottom: 2rem;
    width: 100%;
    background-color: var(--color-gray-50);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .start {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .end {
      display: flex;
      align-items: center;
      gap: 0.6rem;

      p {
        margin: 0;
      }
    }
  }
}
