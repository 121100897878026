.transfer-pension-story-withdrawal-period {
  .header {
    margin-bottom: 3rem;
  }

  .glide-path {
    margin: 1rem 0 1rem 0;
  }

  .heading {
    margin-top: 3rem;
  }

  .save-button {
    margin-top: 4rem;
  }
}
