.transfer-pension-confirm {
  form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .move-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .move-cards-header {
    margin: 0;
  }

  .investment-direction-icon {
    margin-left: 0.6rem;
    display: grid;
    place-items: center;
  }

  .document-header {
    margin-bottom: 0.4rem;
  }

  .agree-on-terms {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }

  .terms-list {
    margin-left: 1.8rem;
    list-style: initial;
  }
}
